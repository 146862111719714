<template>
  <div class="app">
    <div class="title">
    </div>
    <div class="center">
      <div class="login" style="position: relative">
        <div style="margin-top: 50px;font-weight: bold;font-size: 18px;margin-left: 20px"> 用户登录 </div>
        <p  style="color: red;font-size: 12px;position: absolute;top: 68px;left: 26px" >{{loginForm.err}}</p>
        <div class="form-content">
          <el-form ref="loginForm"  :model="loginForm" :rules="rules" label-width="10px">
            <el-form-item label=""  prop="phone">
              <el-input v-model="loginForm.phone" placeholder="手机号" clearable maxLength="11" @change="clearLoginVaildErr"></el-input>
            </el-form-item>
            <el-form-item label="" style="position: relative" prop="pwd">
              <el-input type="password" v-model="loginForm.pwd"  placeholder="密码" clearable ></el-input>
            </el-form-item>
            <el-form-item label="" style="position: relative" prop="vaildCod">
              <el-input v-model="loginForm.vaildCod" clearable placeholder="验证码" style="width:40%;margin-button:10px"/>
              <img :src="vaildCode" placeholder="密码" style="width: 130px; height: 40px; margin-left: 10px; margin-right: 10px; display: inline-block; position: relative; top: 14px;" />
              <el-link style="margin-left: 10px" @click="getVaildCod">下一张</el-link>
            </el-form-item>
            <el-row style="margin-bottom: 20px">
              <el-button type="primary" style="width: 100%" @click="login">登录</el-button>
            </el-row>
            <el-row >
              <el-checkbox style="margin-left: 10px" v-model="checkedPwd">记住密码</el-checkbox>
              <el-link style="margin-right: 10px;float: right;color: red" @click="forgetPwd=true">忘记密码</el-link>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
   <!-- <div class="buttom">
      <p>@CNBOT版权所有v_1.0.0</p>
      <p>地址：湖南省长沙市开福区华创国际C座13层 联系方式：0731-85368365 官网：www.hncnbot.com</p>
    </div>-->

    <el-dialog title="忘记密码"  :visible.sync="forgetPwd"  width="30%" :close-on-click-modal="false" :before-close="resetForgetPwd" >
      <el-form ref="forgetForm"  :model="forgetForm" :rules="rules" label-width="110px">
        <el-form-item label="手机号" style="position: relative" prop="forgetPhone">
          <el-input v-model="forgetForm.forgetPhone" placeholder="手机号" clearable maxLength="11" @change="clearVaildErr"></el-input>
          <p v-if="forgetForm.phoneErr" style="color: red;font-size: 12px;position: absolute;top: 16px" >{{forgetForm.phoneErr}}</p>
        </el-form-item>
        <el-form-item label="短信验证码" prop="forgetVaildCod">
          <el-input v-model="forgetForm.forgetVaildCod" placeholder="短信验证码" style="width: 70%" />
          <el-button  type="primary" style="margin-left: 10px" @click="getPhoneVaild"> 获取验证码</el-button>
        </el-form-item>
        <el-form-item label="新密码" prop="forgetPwd">
          <el-input v-model="forgetForm.forgetPwd" placeholder="请输入16位英文、数组、符号"></el-input>
        </el-form-item>
        <el-form-item label="重复新密码" prop="forgetNewPwd">
          <el-input v-model="forgetForm.forgetNewPwd" placeholder="再次输入密码"></el-input>
        </el-form-item>
         <el-row>
           <el-button type="primary" style="width: 100%"> 提交 </el-button>
         </el-row>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>

import axios from '../node_modules/axios'
export default {
  data(){
    return{
      checkedPwd:'',
      forgetPwd:false,
      vaildCode:'',
      loginForm:{
        phone:'13547224920',
        err:'',
        pwd:'123456',
        vaildCod:''
      },
      forgetForm:{
        forgetPhone:'',
        phoneErr:'',
        forgetVaildCod:'',
        forgetPwd:'',
        forgetNewPwd:'',
      },
      rules:{
        forgetPhone:[{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        forgetVaildCod:[{ required: true, message: '请输入验证码', trigger: 'blur' }],
        forgetPwd:[{ required: true, message: '请输入密码', trigger: 'blur' }],
        forgetNewPwd:[{ required: true, message: '密码不能为空', trigger: 'blur' }],
        vaildCod:[{ required: true, message: '请输入验证码', trigger: 'blur' }],
        phone:[{ required: true, message: '请输入电话', trigger: 'blur' }],
        pwd:[{ required: true, message: '请输入密码', trigger: 'blur' }],
      }

    }
  },
  mounted() {
    this.getVaildCod();
  },
  methods:{
     getVaildCod(){
       axios.get("/cnbots/randCodeImage",{responseType: 'arraybuffer' }).then(res=>{
         this.vaildCode =  'data:image/png;base64,' + btoa(
             new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
         )
       })
     },
    clearVaildErr(){
      this.forgetForm.phoneErr = '';
    },
    clearLoginVaildErr(){
      this.loginForm.err = '';
    },
    getPhoneVaild(){
      this.forgetForm.phoneErr = '';
      this.$refs['forgetForm'].validate((valid) => {
        if(valid){
          axios.post("/cnbots/loginController.do?sendPassWordMessage&phoneNumber="+this.forgetForm.phone,).then(res=>{
            if(res.data.success){
              this.$message({type:"success",message:"验证码已发送成功！"})
            }else{
              this.forgetForm.phoneErr = res.data.msg;
            }
          })
        }
      })
    },
    resetForgetPwd() {
      this.$refs['forgetForm'].resetFields();
      this.forgetPwd=false;
    },
    login(){
      this.loginForm.err = '';
      this.$refs['loginForm'].validate((valid) => {
        if(valid){
          let config={
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
          };
          let formdata = new FormData();
          formdata.append("userName",this.loginForm.phone);
          formdata.append("password",this.loginForm.pwd);
          formdata.append("randCode",this.loginForm.vaildCod);
          formdata.append("ReturnURL",'');
          formdata.append("orgId",'');
          axios.post("cnbots/loginController.do?checkuser",formdata,config).then(res=>{
            if(res.data.success){
				window.location.href = "cnbots/loginController.do?login";
				// window.location.href='http://server.hncnbot.com/cnbots/loginController.do?login'
            }else{
              this.getVaildCod();
              this.loginForm.err = res.data.msg;
            }
          })
        }
      })

    }

  }
}
</script>

<style  scoped>
  .app{
    width: 100%;
    height: 100%;
  }

  .title{
    height: 50px;
    width: 100%;
    background: #1c242b ;
  }
  .center {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url('./assets/bg.png') no-repeat ;
    margin: 0 auto;
    width: 100%;
    height: 700px;
  }
  .center .login{
    height: 398px;
    width: 410px;
    float: right;
    margin-right: 16%;
    margin-top: 6%;
    background: #fff;
    border-radius: 10px;
  }
  .form-content{
    padding: 20px;
    margin-top: 10px;
  }
  .buttom{
    text-align: center;
    padding-top: 50px;
  }


</style>
